import React, { useEffect } from "react";
import CloseIcon from '@material-ui/icons/Close';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from '@mui/material/DialogContent';
import '../styles/inActivePopUp.css';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from "@material-ui/core/Button";
import apiAbmBlaze, { UPLOAD_CSV_ENCRYPTED_FILE } from '../../api';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";

const UploadCSV = (props) => {
    const [filedata, setFiledata] = React.useState("");
    const [CsvResponse, setCSVResponse] = React.useState(false);
    const [uploadcsvHide, setUploadcsvHide] = React.useState(true);
    const [uploadingerorr, setUploadingerror] = React.useState(false);
    const [decryptedCsvData, setDecryptedCsvData] = React.useState('');
    const [startfrom, setStartfrom] = React.useState(false);
    const [downloadpdfinprogress, setdownloadpdfinprogress] = React.useState(false);
    const hiddenFileInput = React.useRef(null);
    const [downloadpdferror, setdownloadpdferror] = React.useState(false);
    const [openSnack, setOpenSnack] = React.useState(false);
    const [snackMsg, setSnackMsg] = React.useState("");
    const [snackSeverity, setSnackSeverity] = React.useState("");
    const [customeSnackbarAnchor, setCustomeSnackbarAnchor] = React.useState({
        vertical: "top",
        horizontal: "center",
    });


    // Resetting state so that, need not be refreshed to decrypt another file
    useEffect(() => {
        if (props.isopen) {
            setFiledata('');
            setCSVResponse(false);
            setUploadcsvHide(true);
            setUploadingerror(false);
            setdownloadpdfinprogress(false);
            setDecryptedCsvData('');
            setStartfrom(false);
        }
    }, [props.isopen])
    const handleFileCSV = (e) => {
        setFiledata([e.target.files[0]]);
        setUploadingerror(false);
    }

    useEffect(() => {
        if (!filedata) {
            return;
        }
        setCSVResponse(true);
        setUploadcsvHide(false);
        let GetCSVFile = filedata[0];
        let formData = new FormData();
        formData.append("uploaded-csv", GetCSVFile);

        apiAbmBlaze.post(UPLOAD_CSV_ENCRYPTED_FILE, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }).then((response) => {
            setCSVResponse(false);
            if (response && response.data) {
                if (!response.data.uuid) {
                    setDecryptedCsvData(response.data)
                } else if (response.data.csv) {
                    setDecryptedCsvData(response.data.csv);
                } else {
                    setDecryptedCsvData(response.data)
                }
            }
        }).catch((error) => {
            if (error) {
                setCSVResponse(false);
                setUploadingerror(true);
                setUploadcsvHide(true);
            }
        })
    }, [filedata])

    const DownloadCsv = (e) => {
        setStartfrom(true);
        if (decryptedCsvData) {
            const url = window.URL.createObjectURL(new Blob([decryptedCsvData], {}));
            const link = document.createElement('a');
            link.href = url;
            link.download = `Decrypted_${filedata[0].name}`;
            link.click();
            window.URL.revokeObjectURL(url);
            setTimeout(() => closingInto(), 1000);
        }
    }
    const handleSnackbar = () => {
        setOpenSnack(false);
    };
    const DownloadPDF = (e) => {
        setStartfrom(true);
        return new Promise((success, fail) => {
            setdownloadpdfinprogress(true);
            let fileName = `Decrypted_${filedata[0].name}`.replace('.csv', '.pdf')
            let formData = new FormData();
            let GetCSVFile = filedata[0];
            formData.append("uploaded-csv", GetCSVFile);
            apiAbmBlaze.post('/api/decrypt-csv?getPdf=true', formData, {
                responseType: 'blob', headers: {
                    'Content-Type': 'multipart/form-data',
                }
            })
                .then(async (res) => {
                    return new Blob([res.data]);
                })
                .then(blob => {
                    const url = URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = fileName;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(url);
                    success('download succes')
                    setdownloadpdfinprogress(false);
                    setTimeout(() => closingInto(), 1000);
                    setdownloadpdferror(false)
                    setOpenSnack(false)
                })
                .catch(e => {
                    console.log('Get CHARS err', e)
                    // fail('Download failed')
                    closingInto()
                    setdownloadpdferror(true)
                    setSnackMsg("Pdf Download failed , Please check if you're uploading an encrypted file downloaded from an ABM device")
                    setSnackSeverity('error')
                    setOpenSnack(true)

                })
        })
    }
    const closingInto = () => {
        props.closePopup();
        if (startfrom) {
            setUploadcsvHide(true);
        }
    }
    return (
        <>

            <AlertSnackBar
                msg={snackMsg}
                flag={openSnack}
                onClose={handleSnackbar}
                key={"cupSnackbar"}
                anchorOrigin={customeSnackbarAnchor}
                severity={snackSeverity}
            />


            <div className="media-patient upload-csv">
                <Dialog
                    open={props.isopen}
                    onClose={closingInto}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title media-patient">
                        <div className="Ptient-heading-container">
                            <div className="patient-title"></div>
                            <div className="iconForCancelptient">
                                <CloseIcon onClick={closingInto} style={{ cursor: "pointer" }} />
                            </div>
                        </div>
                    </DialogTitle>
                    {uploadcsvHide ? <DialogContent className="media-patient">
                        <DialogContentText id="alert-dialog-description">
                            <div style={{ height: '250px' }}>
                                <div className="uploadingcsv-heading">
                                    <span className="uploadcsv-text" style={{ fontSize: '20px' }}>Select upload to search for the log file.</span>
                                </div>

                                <div className="file-upload-container">
                                    <button class="btn" onClick={() => { hiddenFileInput.current.click(); }}> <i class="material-icons">cloud_upload</i> &nbsp;&nbsp;Upload</button>
                                    <input type="file" ref={hiddenFileInput} name="myfile" accept=".csv" style={{ display: 'none' }} onChange={(e) => handleFileCSV(e)} />
                                </div>
                            </div>
                        </DialogContentText>
                        {uploadingerorr ? <span style={{ color: "red", fontWeight: "400", textAlign: "center", display: "block", marginTop: "10px" }}>Something Went Wrong, Please Try Again!</span> : null}
                    </DialogContent> : CsvResponse && !uploadcsvHide ? <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center", marginBottom: "30px", height: "250px" }} className="media-patient">
                        <CircularProgress />
                    </Box> : <div className="media-patient" style={{ height: "280px" }}>
                        <p style={{ textAlign: "center", marginBottom: "10px", fontFamily: "Roboto", fontWeight: 500, color: "#646a8d", fontSize: "14px", marginTop: "50px", fontSize: '20px' }}>Select Download to save the decrypted file in a csv format.<br />
                            {/* {`Decrypted_${filedata[0].name}`} */}
                        </p>
                        <div class="upload-btn-wrapper" style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "50px" }}>
                            <button class="btn2" onClick={(e) => DownloadCsv(e)}><i class="fa fa-download" aria-hidden="true"></i> &nbsp;&nbsp;Download CSV</button>
                            <input type="button" name="myfile" />

                            {/* <button class="btn2" onClick={(e) => DownloadPDF(e)}><i class="fa fa-download" aria-hidden="true"></i> &nbsp;&nbsp;{downloadpdfinprogress?'Downloading... PDF':'Download PDF'}</button> */}
                        </div>
                    </div>}
                </Dialog>
            </div>
        </>
    )
}
export default UploadCSV;
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export const AlertSnackBar = ({
    flag,
    onClose,
    anchorOrigin,
    msg,
    severity,
}) => {
    return (
        <Snackbar
            anchorOrigin={
                anchorOrigin ? anchorOrigin : { vertical: "top", horizontal: "right" }
            }
            open={flag}
            autoHideDuration={5000}
            onClose={onClose}
        >
            <Alert onClose={onClose} severity={severity ? severity : "success"}>
                {msg}
            </Alert>
        </Snackbar>
    );
};